<template>
  <b-overlay
    :show="loading"
    spinner-variant="primary"
    variant="white"
  >
    <b-card
      no-body
      variant="light"
      class="border overflow-hidden"
    >
      <b-table-simple
        :striped="true"
        :bordered="false"
        :borderless="true"
        :outlined="false"
        :small="false"
        :hover="false"
        :dark="false"
        :fixed="false"
        :responsive="true"
        class="mb-0"
      >
        <b-tbody>
          <b-tr v-if="get(shipment, 'statusDescription')">
            <b-th>Обновлен статус</b-th>
            <b-td>
              <div
                v-if="get(shipmentDetail, 'currentStatus.updateTime')"
                class="small"
              >
                {{ moment(get(shipmentDetail, 'currentStatus.updateTime')).format('YYYY-MM-DD HH:mm:ss') }}
              </div>
            </b-td>
          </b-tr>
          <b-tr v-if="get(shipment, 'shipmentType')">
            <b-th>Способ отгрузки</b-th>
            <b-td>
              <div
                v-if="get(shipment, 'shipmentType') === 'IMPORT'"
              >
                Вы самостоятельно привозите заказы в выбранный сортировочный центр или пункт приема заказов
              </div>
              <div
                v-if="get(shipment, 'shipmentType') === 'WITHDRAW'"
              >
                Вы отгружаете заказы со своего склада курьерам Яндекс Маркета
              </div>
            </b-td>
          </b-tr>
          <b-tr v-if="get(shipmentDetail, 'dwarehouse')">
            <b-th>Склад отправления</b-th>
            <b-td>
              <div class="small">
                {{ get(shipmentDetail, 'warehouse.id') }}
              </div>
              <div class="small">
                <strong>{{ get(shipmentDetail, 'warehouse.name') }}</strong>
              </div>
              <div class="small">
                {{ get(shipmentDetail, 'warehouse.address') }}
              </div>
            </b-td>
          </b-tr>
          <b-tr v-if="get(shipmentDetail, 'warehouseTo')">
            <b-th>Склад назначения</b-th>
            <b-td>
              <div class="small">
                {{ get(shipmentDetail, 'warehouseTo.id') }}
              </div>
              <div class="small">
                <strong>{{ get(shipmentDetail, 'warehouseTo.name') }}</strong>
              </div>
              <div class="small">
                {{ get(shipmentDetail, 'warehouseTo.address') }}
              </div>
            </b-td>
          </b-tr>
          <b-tr v-if="get(shipmentDetail, 'deliveryService')">
            <b-th>Служба доставки</b-th>
            <b-td>
              <div class="small">
                {{ get(shipmentDetail, 'deliveryService.id') }}
              </div>
              <div class="small">
                <strong>{{ get(shipmentDetail, 'deliveryService.name') }}</strong>
              </div>
            </b-td>
          </b-tr>
          <!--                <b-tr v-if="get(shipment, 'detail.availableActions')">-->
          <!--                  <b-th>Доступные действия</b-th>-->
          <!--                  <b-td>-->
          <!--                    {{ get(shipment, 'detail.availableActions', []).join(', ') }}-->
          <!--                  </b-td>-->
          <!--                </b-tr>-->
        </b-tbody>
      </b-table-simple>
      <b-card-body v-if="canGetAct || orders.length || canConfirmShipment">
        <b-button
          v-if="canGetAct"
          variant="outline-primary"
          class=""
          size="sm"
          @click="getAct"
        >
          Скачать акт
        </b-button>
        <b-button
          v-if="canConfirmShipment"
          variant="outline-primary"
          class=""
          size="sm"
          @click="confirmShipment"
        >
          Подтвердить
        </b-button>
        <b-table-simple
          :striped="true"
          :bordered="false"
          :borderless="true"
          :outlined="true"
          :small="false"
          :hover="false"
          :dark="false"
          :fixed="false"
          :responsive="true"
          class="mb-0 mt-1"
        >
          <b-thead>
            <b-th>
              Заказ
            </b-th>
            <b-th>
              Сумма
            </b-th>
            <b-th>
              Действия
            </b-th>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="order in orders"
              :key="'o' + order.id"
            >
              <b-td>
                <div class="">
                  <b-link
                    :to="{
                      name: 'order.show',
                      params: { id: order.id, type: order.source},
                    }"
                  >
                    № {{ order.id }} / {{ order.external_id }}
                  </b-link>
                </div>
                <div
                  class=""
                  style="margin-top: 2px; margin-bottom: 5px;"
                >
                  <b-badge
                    variant="primary"
                  >
                    {{ get(getStatusById(order.status_id), 'name') }}
                  </b-badge>
                </div>
                <div
                  v-for="item in get(order,'external_data.items',[])"
                  :key="item.id"
                  class="small"
                >

                  <div>
                    <b-link
                      v-if="(item.offerId + '').includes('as-') || (item.offerId + '').includes('ast-')"
                      :to="{
                        name: 'offers.show',
                        params: { id: (item.offerId + '').split('-')[1] }
                      }"
                    >
                      {{ item.offerName }}
                    </b-link>
                    <template v-else>
                      {{ item.offerName }}
                    </template>
                  </div>
                  <div class="text-secondary">
                    {{ item.offerId }} | Количество: {{ item.count }}
                  </div>
                </div>
              </b-td>
              <b-td>
                {{ get(order, 'external_data.totalWithSubsidy', 0).toLocaleString() }}
              </b-td>
              <b-td>
                <b-form-row
                  class="align-items-center"
                >
                  <b-col>
                    <label for="">
                      Грузовые места
                    </label>

                    <b-form-spinbutton
                      v-model="order.external_shipment_boxes"
                      min="1"
                      size="sm"
                      :disabled="!checkCanEditedExternalShipmentBoxes(order) || order.editing"
                    />
                  </b-col>
                  <b-col
                    v-if="checkCanEditedExternalShipmentBoxes(order)"
                  >
                    <b-button
                      size="sm"
                      variant="outline-secondary"
                      class="d-block w-100 mt-75"
                      :disabled="order.editing"
                      @click="updateExternalShipmentBoxes(order.id)"
                    >
                      Сохранить
                    </b-button>
                  </b-col>
                </b-form-row>
                <b-button
                  v-if="checkCanGetLabels(order)"
                  class="mt-2 w-100"
                  variant="outline-primary"
                  @click="getLabels(order)"
                >
                  Получить ярлыки
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card-body>
    </b-card>
  </b-overlay>

</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BOverlay,
  BRow,
  BFormRow,
  BTable,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BFormSpinbutton,
  BTableSimple,
  BTr,
  BTd,
  BTh,
  BThead,
  BTbody,
} from 'bootstrap-vue'

import moment from 'moment'

import {
  getCurrentInstance, onMounted, ref, computed, watch,
} from 'vue'
import get from 'lodash/get'
import {
  getMerlionFreshYmfbsIntegrationShipment as getYandexMarketFbsIntegrationShipment,
  getMerlionFreshYmfbsIntegrationShipmentAct as getYandexMarketFbsIntegrationShipmentAct,
  confirmMerlionFreshYmfbsIntegrationShipment as confirmYandexMarketFbsIntegrationShipment,
} from '@/services/main-api/integrations/merlion-fresh-ymfbs'

import {
  getLabels as getLabelsApi,
  getOrders as getOrdersApi,
  updateOrder as updateOrderApi,
} from '@/services/main-api/shop/orders/orders'
import { getStatuses as getStatusesApi } from '@/services/main-api/shop/statuses'

export default {
  name: 'ShipmentDetail',
  components: {
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BOverlay,
    BRow,
    BFormRow,
    BTable,
    BInputGroup,
    BInputGroupPrepend,
    BLink,
    BFormSpinbutton,
    BTableSimple,
    BTr,
    BTd,
    BTh,
    BThead,
    BTbody,
  },
  props: {
    shipment: {
      type: Object,
      default: null,
    },
    merlionFreshYmfbsIntegrationId: {
      type: [Number, String],
      default: null,
    },

  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const loading = ref(false)

    moment.locale('ru')

    const shipmentDetail = ref(null)

    const getShipmentDetail = async () => {
      try {
        const res = await getYandexMarketFbsIntegrationShipment({
          id: props.merlionFreshYmfbsIntegrationId,
          shipmentID: props.shipment.id,
        })

        shipmentDetail.value = res.data.data
      } catch (e) {
        shipmentDetail.value = null
      }
    }

    const shipmentOrdersIds = computed(() => get(shipmentDetail.value, 'orderIds', []))

    const orders = ref([])

    const getOrders = async () => {
      try {
        const res = await getOrdersApi({
          project: instance.$store.getters['workingMode/selected_project_id'],
          perPage: 1000,
          search: shipmentOrdersIds.value.map(i => `external_id:${i}`).join(';'),
        })
        orders.value = res.data.data.filter(i => i.source_id === props.merlionFreshYmfbsIntegrationId).map(o => ({
          ...o,
          editing: false,
        }))
      } catch (e) {
        orders.value = []
      }
    }

    // BEGIN Act
    const canGetAct = computed(() => !!get(shipmentDetail.value, 'availableActions', []).includes('DOWNLOAD_ACT'))

    const getAct = async () => {
      try {
        const res = await getYandexMarketFbsIntegrationShipmentAct({
          id: props.merlionFreshYmfbsIntegrationId,
          shipmentID: get(shipmentDetail.value, 'id'),
        })
        const url = window.URL.createObjectURL(res.data)

        window.open(url)
      } catch (e) {
        // ...
      }
    }
    // END Act

    // BEGIN Confirm Shipment
    const canConfirmShipment = computed(() => !!get(shipmentDetail.value, 'availableActions', []).includes('CONFIRM'))

    const confirmShipment = async () => {
      try {
        await confirmYandexMarketFbsIntegrationShipment({
          id: props.merlionFreshYmfbsIntegrationId,
          shipmentID: get(shipmentDetail.value, 'id'),
        })

        loading.value = true
        await getShipmentDetail()
        loading.value = false
      } catch (e) {
        // ...
      }
    }
    // END Confirm Shipment

    // BEGIN Statuses
    const statuses = ref([])
    const getStatuses = async () => {
      try {
        const res = await getStatusesApi({
          project: instance.$store.getters['workingMode/selected_project_id'],
          perPage: 100,
          orderBy: 'sort_order',
          sortedBy: 'asc',
        })

        statuses.value = res.data.data
      } catch (e) {
        statuses.value = []
      }
    }

    const getVariantByCode = code => {
      let variant = 'success'
      // success
      // info
      // warning
      // primary
      // secondary
      switch (code) {
        case 'delivery':
          variant = 'info'
          break
        case 'finished':
          variant = 'secondary'
          break
        default:
          variant = 'success'
      }
      return variant
    }

    const getStatusById = id => statuses.value.find(s => s.id === id)
    // END Statuses

    // BEGIN external_shipment_boxes
    const updateExternalShipmentBoxes = async id => {
      const order = orders.value.find(o => o.id === id)

      if (!order) {
        return
      }

      try {
        order.editing = true
        await updateOrderApi({
          id: order.id,
          external_shipment_boxes: order.external_shipment_boxes,
        })
      } catch (e) {
        // ...
      } finally {
        order.editing = false
      }
    }

    const checkCanEditedExternalShipmentBoxes = order => {
      const status = getStatusById(order.status_id)

      return ['preparation', 'ready_to_delivery'].includes(get(status, 'code', ''))
    }
    // END external_shipment_boxes

    // BEGIN Labels
    const checkCanGetLabels = order => {
      const status = getStatusById(order.status_id)

      return ['preparation', 'ready_to_delivery', 'delivery'].includes(get(status, 'code', ''))
    }
    const getLabels = async order => {
      try {
        const res = await getLabelsApi({ id: order.id })
        const url = window.URL.createObjectURL(res.data)

        window.open(url)
      } catch (e) {
        // ...
      }
    }
    // END Labels

    onMounted(async () => {
      loading.value = true

      await Promise.all([
        (async () => {
          await getShipmentDetail()
          if (shipmentOrdersIds.value.length) {
            await getOrders()
          }
        })(),
        getStatuses(),
      ])

      loading.value = false
    })

    return {
      loading,

      shipmentDetail,
      shipmentOrdersIds,

      orders,

      // Act
      canGetAct,
      getAct,

      canConfirmShipment,
      confirmShipment,

      getStatusById,
      getVariantByCode,

      checkCanEditedExternalShipmentBoxes,
      updateExternalShipmentBoxes,

      checkCanGetLabels,
      getLabels,

      get,
      moment,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
