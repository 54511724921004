<template>
  <b-overlay
    :show="loading || shipmentLoading"
    spinner-variant="primary"
  >
    <b-card
      v-if="integrations.length"
      no-body
    >
      <b-tabs
        v-model="activeTabIndex"
      >
        <b-tab
          v-for="integration in integrations"
          :key="'tab' + integration.id"
          :title="integration.name"
          lazy
        >
          <div class="px-1 pb-2">
            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <label for="shipments-date-from">От</label>
                <b-form-datepicker
                  id="shipments-date-from"
                  v-model="shipmentsDateFrom"
                  placeholder="Начало периода"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  locale="ru"
                  :max="shipmentsDateTo"
                  hide-header
                  label-help="Можно использовать стрелки для навигации по датам"
                  :start-weekday="1"
                />
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <label for="shipments-date-to">До</label>
                <b-form-datepicker
                  id="shipments-date-to"
                  v-model="shipmentsDateTo"
                  :min="shipmentsDateFrom"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  placeholder="Окончание периода"
                  locale="ru"
                  hide-header
                  :start-weekday="1"
                  label-help="Можно использовать стрелки для навигации по датам"
                />
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refShipmentListTable"
            class="position-relative list-view-table"
            :items="shipments"
            responsive
            primary-key="id"
            show-empty
            empty-text="Ничего не найдено!"
            no-local-sorting
            :fields="[
              { key: 'shipment', label: 'Отгрузка', sortable: false},
              { key: 'info', label: 'Info', sortable: false},
            ]"
          >
            <template #cell(shipment)="data">
              <div class="mb-1">
                <strong>{{ get(data, 'item.id') }}</strong><br>
                {{ moment(data.item.planIntervalFrom).format('DD.MM.YY') }}
              </div>
              <div class="">
                <b-badge
                  pill
                  variant="primary"
                >
                  {{ get(data, 'item.statusDescription') || get(data, 'item.status') }}
                </b-badge>
              </div>
            </template>

            <template #cell(info)="data">
              <div class="small">
                <div class="">
                  <strong>Плановая дата и время отгрузки</strong><br>
                  {{ moment(data.item.planIntervalFrom).format('Do MMMM YYYY') }} c {{ moment(data.item.planIntervalFrom).format('HH:mm') }} до {{ moment(data.item.planIntervalTo).format('HH:mm') }}
                </div>

                <div class="mt-1">
                  <strong>Способ отгрузки</strong><br>
                  <div
                    v-if="get(data, 'item.shipmentType') === 'IMPORT'"
                  >
                    Вы самостоятельно привозите заказы в выбранный сортировочный центр или пункт приема заказов
                  </div>
                  <div
                    v-if="get(data, 'item.shipmentType') === 'WITHDRAW'"
                  >
                    Вы отгружаете заказы со своего склада курьерам Яндекс Маркета
                  </div>
                </div>

                <div
                  v-if="get(data, 'item.draftCount')"
                  class="mt-1"
                >
                  <strong>Заказов:</strong> {{ get(data, 'item.draftCount') }}
                </div>

                <div
                  v-if="get(data, 'item.plannedCount')"
                  class="mt-1"
                >
                  <strong>Отгружено:</strong> {{ get(data, 'item.plannedCount') }}
                </div>

                <div
                  v-if="get(data, 'item.factCount')"
                  class="mt-1"
                >
                  <strong>Количество заказов, принятых в сортировочном центре или пункте приема</strong><br>
                  {{ get(data, 'item.factCount') }}
                </div>
              </div>

              <b-button
                class="mt-1"
                variant="outline-primary"
                size="sm"
                @click="data.toggleDetails"
              >
                {{ data.detailsShowing ? 'Скрыть' : 'Подробнее' }}
              </b-button>
            </template>

            <template #row-details="row">
              <y-m-f-b-s-shipment-detail
                v-if="activeIntegration.type === 'yandex_market_fbs'"
                :shipment="row.item"
                :yandex-market-fbs-integration-id="activeIntegration.id"
              />
              <m-f-y-f-b-s-shipment-detail
                v-if="activeIntegration.type === 'merlion_fresh_ymfbs'"
                :shipment="row.item"
                :merlion-fresh-ymfbs-integration-id="activeIntegration.id"
              />
            </template>

          </b-table>
        </b-tab>
      </b-tabs>

    </b-card>

    <b-alert
      v-else
      variant="secondary"
      show
    >
      <h4 class="alert-heading">
        Сообщение
      </h4>
      <div class="alert-body">
        У вас нет интеграций с отгрузками.
      </div>
    </b-alert>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BDropdown, BDropdownItem,
  BPagination, BOverlay, BLink, BBadge, BTab, BTabs, BAlert, BCardBody, BFormDatepicker, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import {
  getCurrentInstance, onMounted, ref, computed, watch,
} from 'vue'
import get from 'lodash/get'
import moment from 'moment'
import useCrudList from '@/composables/useCrudList'
import { getYandexMarketFbsIntegrationShipments } from '@/services/main-api/integrations/yandex-market-fbs'
import { getMerlionFreshYmfbsIntegrationShipments } from '@/services/main-api/integrations/merlion-fresh-ymfbs'
import ListCrud from '@/components/crud/ListCrud.vue'

import useIntegrations from '@/composables/useIntegrations'
import YMFBSShipmentDetail from '../components/yandex-market-fbs/ShipmentDetail.vue'
import MFYFBSShipmentDetail from '../components/merlion-fresh-ymfbs/ShipmentDetail.vue'

export default {
  name: 'ListView',
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BLink,
    BBadge,
    vSelect,
    BTab,
    BTabs,
    BAlert,
    ListCrud,
    BFormDatepicker,
    BFormGroup,
    YMFBSShipmentDetail,
    MFYFBSShipmentDetail,
  },
  setup(props, { root }) {
    const instance = getCurrentInstance()
    moment.locale('ru')

    const loading = ref(false)

    // BEGIN Integrations
    const { items: integrationsTemp, getItems: getIntegrations } = useIntegrations()
    const integrations = computed(() => integrationsTemp.value.filter(i => i.type === 'yandex_market_fbs' || i.type === 'merlion_fresh_ymfbs'))
    // END Integrations

    // BEGIN ActiveIntegration
    const activeTabIndex = ref(0)
    const activeIntegration = computed(() => get(integrations.value, `[${activeTabIndex.value}]`, null))
    // END ActiveIntegration

    // BEGIN Shipments
    const shipmentsDateFrom = ref(null)
    const shipmentsDateTo = ref(null)
    const shipments = ref([])
    const shipmentLoading = ref(false)

    const getShipments = async () => {
      shipmentLoading.value = true
      try {
        if (!activeIntegration.value || !activeIntegration.value.id) {
          throw new Error('Not shipment id')
        }

        const dateFrom = moment(shipmentsDateFrom.value, 'YYYY-MM-DD').format('DD-MM-YYYY')
        const dateTo = moment(shipmentsDateTo.value, 'YYYY-MM-DD').format('DD-MM-YYYY')

        if (activeIntegration.value.type === 'merlion_fresh_ymfbs') {
          const res = await getMerlionFreshYmfbsIntegrationShipments({
            id: activeIntegration.value.id,
            dateFrom,
            dateTo,
          })
          shipments.value = res.data.data.filter(sh => get(sh, 'draftCount', 0) > 0).reverse()

          console.log(shipments.value)
        } else if (activeIntegration.value.type === 'yandex_market_fbs') {
          const res = await getYandexMarketFbsIntegrationShipments({
            id: activeIntegration.value.id,
            dateFrom,
            dateTo,
          })
          shipments.value = res.data.data.filter(sh => get(sh, 'draftCount', 0) > 0).reverse()
        } else {
          shipments.value = []
        }
      } catch (e) {
        shipments.value = []
      } finally {
        shipmentLoading.value = false
      }
    }

    watch(activeIntegration, async () => {
      await getShipments()
    })
    watch(shipmentsDateFrom, async () => {
      await getShipments()
    })
    watch(shipmentsDateTo, async () => {
      await getShipments()
    })
    // END Shipments

    const getData = async () => {
      loading.value = true
      await getIntegrations()
      loading.value = false
    }

    onMounted(async () => {
      shipmentsDateFrom.value = moment().format('YYYY-MM-DD')
      shipmentsDateTo.value = moment().add(1, 'days').format('YYYY-MM-DD')

      await getData()
    })

    return {
      loading,

      integrations,

      shipments,
      shipmentLoading,
      shipmentsDateFrom,
      shipmentsDateTo,

      activeTabIndex,
      activeIntegration,

      get,
      moment,
    }
  },
}
</script>

<style scoped lang="scss">
.per-page-selector {
  width: 90px;
}
.list-view-table{
  min-height: 145px;
}

@import '@core/scss/vue/libs/vue-select.scss';
</style>
